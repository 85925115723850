import React, {useState} from 'react';
import './TerminalPage.css'; // Assuming you have or will create styles for this
import QAAgentsTab from './QAAgentsTab';
import AIEmployeesTab from './AIEmployeesTab';
import DailyRemindersTab from './DailyRemindersTab';
import Sidebar from './Sidebar';

const TerminalPage = () => {
    const [activeTab, setActiveTab] = useState('qaAgents');

    return (
        <div className="terminalPage">
            <Sidebar/>
            <div className="terminalContent">
                <div className="tabs">
                    <button onClick={() => setActiveTab('qaAgents')}>QA Agents</button>
                    <button onClick={() => setActiveTab('aiEmployees')}>AI Employees</button>
                    <button onClick={() => setActiveTab('dailyReminders')}>Daily Reminders</button>
                </div>
                <div className="content">
                    {activeTab === 'qaAgents' && <QAAgentsTab/>}
                    {activeTab === 'aiEmployees' && <AIEmployeesTab/>}
                    {activeTab === 'dailyReminders' && <DailyRemindersTab/>}
                </div>
            </div>
        </div>
    );
};

export default TerminalPage;
