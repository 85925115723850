import React, {useState, useEffect, useRef} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import './MessageInputForm.css';


const MessageInputForm = ({onSendMessage, chatInfo}) => {
    const [newMessage, setNewMessage] = useState('');
    const textareaRef = useRef(null);
    const {chatId} = useParams();

    useEffect(() => {
        const numberOfLineBreaks = (newMessage.match(/\n/g) || []).length;
        // Минимальное количество строк = 1, максимальное — по вашему усмотрению, например, 5
        const newRows = Math.min(Math.max(numberOfLineBreaks + 1, 1), 30);
        textareaRef.current.rows = newRows;
    }, [newMessage]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!newMessage.trim()) return;
        onSendMessage(newMessage);
        setNewMessage('');
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    const handlePing = (message) => {
        // URL API для вызова конечной точки follow-on
        const url = `${process.env.REACT_APP_API_URL}/follow-on`;

        const data = {
            chatId: chatId,
            message: newMessage, // Используйте newMessage напрямую из стейта
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Ping sent successfully:', data);
                // Обработка ответа
            })
            .catch((error) => {
                console.error('Error sending ping:', error);
            });
    };

    const handleAiCallClick = (name, phone) => {
        const url = `${process.env.REACT_APP_API_URL}/ai-call`; // Update this to your Flask app's URL
        const data = {
            name: name,
            phone: phone,
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                console.log('AI call response:', data);
            })
            .catch(error => {
                console.error('Error initiating AI call:', error);
            });
    };


    return (
        <form className="messageInputForm" onSubmit={handleSubmit}>
      <textarea
          ref={textareaRef}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message..."
          className="messageInput"
          rows="1"
          onKeyDown={handleKeyDown}
      ></textarea>
            <img
                style={{
                    marginRight: '10px',
                    height: '40px',
                    padding: '6px',
                    borderRadius: '4px'
                }} // Добавлены inline стили для примера
                src="/ai-call.png"
                alt="AI Call"
                className="aiCallButton"
                onClick={() => handleAiCallClick(chatInfo.participant, chatInfo.phoneNumber)}// Назначаем обработчик клика
            />
            <button
                type="button" // Укажите type="button", чтобы предотвратить отправку формы при нажатии
                onClick={handlePing} // Функция, которая будет вызвана при нажатии
                className="pingButton" // Класс для стилей кнопки
            >
                Ping
            </button>
            <button type="submit" className="sendMessageButton">Send</button>
        </form>
    );
};

export default MessageInputForm;
