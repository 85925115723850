import React from 'react';

const AnalyticsPage = () => {
  return (
    <div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
      <iframe
        src="https://metabase-t81x.onrender.com/public/dashboard/15c47ca7-83b5-4d71-bf3e-4d3010c16490"
        frameBorder="0"
        style={{ width: '100%', height: '100%', border: 'none' }}
        allowTransparency
        title="Analytics Dashboard"
      ></iframe>
    </div>
  );
};

export default AnalyticsPage;
