import React, {useState, useEffect} from 'react';
import {FiMessageSquare, FiAlertCircle, FiMessageCircle} from 'react-icons/fi';
import {useNavigate} from 'react-router-dom';
import './QAAgentsTab.css';


const QAAgentsTab = () => {
    const [agents, setAgents] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Simulate fetching QA agents
        const fetchQAAgents = async () => {
            try {
                const response = await fetch('https://qa-api.onrender.com/api/qa-agents');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                // Since the data is an object, convert it to an array of its values
                const agentsArray = Object.entries(data).map(([key, item]) => ({
                    ...item.data,
                    id: key, // Use the key as the id
                    first_message: item.first_message,
                    status: 'Idle', // Default status
                    system_prompt: item.system_prompt
                }))
                    .filter(agent => agent.first_message); // Filters out agents with an empty first_message

                setAgents(agentsArray);
            } catch (error) {
                console.error('Failed to fetch agents:', error);
                // Handle the error appropriately
                setAgents([]);
            }
        };


        fetchQAAgents();
    }, []);

    const startTest = async (agentId, event) => {
        event.stopPropagation();
        // Uncomment and adjust according to your API's requirements
        await fetch('https://qa-api.onrender.com/api/process', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({user_id: agentId}),
        });

        console.log(`Starting test for agent ${agentId}`);
        setAgents(agents.map(agent => agent.id === agentId ? {...agent, status: 'Running'} : agent));
    };

    const startAllTests = async () => {
        // Iterate over each agent and call startTest for each one
        agents.forEach(agent => {
            // Directly call startTest with each agent's id
            // Since startTest already handles the async operation, no await here
            // Pass a dummy event object with stopPropagation to satisfy startTest's signature
            startTest(agent.id, {
                stopPropagation: () => {
                }
            });
        });

        console.log('Requested to start tests for all agents');
    };


    const handleAgentClick = (agentId) => {
        navigate(`/chat/${agentId}`); // Navigate to /chat/{id}
    };


    return (
        <div className="qaAgentsContainer">
            <h2>QA Agents</h2>
            <ul className="qaAgentsList">
                {agents.map((agent) => (
                    <li key={agent.id} className="qaAgentItem" onClick={() => handleAgentClick(agent.id)}>
                        <div>{agent.username}</div>
                        <div>Status: {agent.status}</div>
                        <div className="info-icons">
                            {/* System Prompt Icon */}
                            <div className="tooltip">
                                <FiAlertCircle/>
                                <span className="tooltiptext">{agent.system_prompt}</span>
                            </div>
                            {/* First Message Icon */}
                            <div className="tooltip">
                                <FiMessageSquare/>
                                <span className="tooltiptext">{agent.first_message}</span>
                            </div>
                        </div>
                        <button onClick={(e) => startTest(agent.id, e)} className="qaAgentButton">Start Test</button>
                    </li>
                ))}
            </ul>
            <button onClick={startAllTests} className="qaRunAllButton">Run All Tests</button>
        </div>
    );
};

export default QAAgentsTab;
