import React, {useState, useEffect, useRef} from 'react';
import {GoogleMap, LoadScript, Marker, InfoWindow} from '@react-google-maps/api';
import {TailSpin} from 'react-loader-spinner';
import './ListingsPage.css';

const containerStyle = {
    width: '100%',
    height: '100vh'
};

// Dummy center position
const center = {
    lat: 37.7749,
    lng: -122.4194
};


const ListingsPage = () => {
        const [query, setQuery] = useState('');
        const [listings, setListings] = useState([]);
        const [selectedListing, setSelectedListing] = useState(null);
        const [isLoading, setIsLoading] = useState(false);
        const [activeMarker, setActiveMarker] = useState(null);
        const [isMapVisible, setIsMapVisible] = useState(false);
        const [isFirst, setIsFirst] = useState(true);
        const mapRef = useRef(null); // Reference to the GoogleMap instance


        const handleActiveMarker = (marker) => {
            if (marker === activeMarker) {
                return;
            }
            setActiveMarker(marker);
        };

        const handleOnMouseOver = (index) => () => {
            handleActiveMarker(index);
        };

        const handleOnMouseExit = () => {
            handleActiveMarker(null);
        };

        useEffect(() => {
            fetchListings(); // Call fetchListings when the component mounts
        }, []);

        // Функция для отправки запроса
        const fetchListings = async () => {
            setIsLoading(true); // Начало загрузки

            try {
                const authToken = localStorage.getItem('token')
                const response = await fetch(`${process.env.REACT_APP_API_URL}/listings`, {
                    method: 'POST', // Предположим, что endpoint ожидает POST-запрос
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': authToken,
                    },
                    body: JSON.stringify({query}), // Тело запроса с текстом поиска
                });
                const data = await response.json();
                setListings(data); // Сохраняем полученные данные в состоянии
            } catch (error) {
                console.error('Ошибка при получении объявлений:', error);
            } finally {
                setIsLoading(false); // Загрузка завершена
                // setIsMapVisible(false)
            }
        };

        const handleMarkerClick = (listing, url) => {
            // Check if the listing has a URL
            if (url) {
                window.open(listing.url, '_blank');
            } else {
                // If no URL, construct a WhatsApp message link with details from the listing
                const message = `Hello, I'm interested in the property at ${listing.location} with ${listing.raw_data.bedrooms} bedrooms and ${listing.raw_data.bathrooms} bathrooms. Can you tell me more about it?`;
                const whatsappLink = `https://wa.me/14156589193?text=${encodeURIComponent(message)}`;
                window.open(whatsappLink, '_blank');
            }
        };

        const handleCallAgentClick = () => {
            // If no URL, construct a WhatsApp message link with details from the listing
            const message = `Hello, I'm interested in buying property.`;
            const whatsappLink = `https://wa.me/14156589193?text=${encodeURIComponent(message)}`;
            window.open(whatsappLink, '_blank');
        }


        function formatPropertyType(inputString) {
            return inputString
                // Split the string into an array by underscore
                .split('_')
                // Map through each element in the array
                .map(word =>
                    // Convert the first character to uppercase and the rest to lowercase
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                // Join the array elements back into a string, separated by spaces
                .join(' ');
        }

// Обработчик отправки формы
        const handleSubmit = (e) => {
            e.preventDefault();
            fetchListings();
        };

        const handleListingClick = (listing) => {
            // Assuming each listing has latitude and longitude
            const {latitude, longitude} = listing;
            setSelectedListing({
                lat: parseFloat(latitude),
                lng: parseFloat(longitude)
            });
        };

        const toggleMapVisibility = () => {
            setIsMapVisible(!isMapVisible);
            // Scroll to the top of the page whenever the map visibility is toggled
            window.scrollTo(0, 0);
        };

        useEffect(() => {
            if (isMapVisible && listings.length > 0 && mapRef.current) {
                const bounds = new window.google.maps.LatLngBounds();
                listings.forEach(({raw_data}) => {
                    const lat = parseFloat(raw_data.latitude);
                    const lng = parseFloat(raw_data.longitude);

                    if (!isNaN(lat) && !isNaN(lng)) { // Ensure both lat and lng are numbers
                        bounds.extend(new window.google.maps.LatLng(lat, lng));
                    }
                });
                mapRef.current.fitBounds(bounds);
            }
        }, [listings, isMapVisible]); // Adjust map bounds when listings or visibility changes

        const onLoad = (map) => {
            mapRef.current = map; // Save map instance for later use
        };

        function formatPrice(listing) {
            // Check if listing.raw_data or listing.raw_data.price is undefined
            if (!listing || !listing.raw_data || typeof listing.raw_data.price !== 'number') {
                return "Price not available"; // Or any default value you see fit
            }

            // If price exists and is a number, format it
            return listing.raw_data.price.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
            });
        }


        return (
            <LoadScript
                googleMapsApiKey="AIzaSyBC81_VJ41UoVcgk9etYu5fgDzr9lA3K78" // Replace with your Google Maps API key
            >
                <div className="listingsPage">
                    <button
                        className="mapToggleButton"
                        onClick={toggleMapVisibility}
                    >
                        {isMapVisible ? '🏠' : '🗺️'}
                    </button>
                    <button
                        className="pulseButton"
                        onClick={handleCallAgentClick}
                    >
                        <img src="/WhatsApp_icon.png.webp" alt="" style={{width: '60px', height: '60px'}}/>
                        {/*👨‍💼❓📞*/}
                    </button>
                    <header className="listingsHeader">
                        <h1 className="animated">Agents. Tours. Loans. Homes.</h1>
                        <form className="searchForm" onSubmit={handleSubmit}>
                            <input
                                type="text"
                                className="searchInput"
                                value={query}
                                onChange={e => setQuery(e.target.value)}
                                placeholder="What are you looking for?"
                            />
                            <button type="submit" className="searchButton">Search</button>
                        </form>


                    </header>
                    <div className={`mapContainer ${isMapVisible ? '' : 'hidden'}`}>

                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            onLoad={onLoad} // Save map instance on load
                            zoom={12}
                        >
                            {listings.map((listing, index) => (
                                <Marker
                                    key={index}
                                    position={{lat: listing.raw_data.latitude, lng: listing.raw_data.longitude}}
                                    onMouseOver={handleOnMouseOver(index)}
                                    onMouseOut={handleOnMouseExit}
                                    // onClick={() => handleMarkerClick(listing, listing.url)}
                                    onClick={() => handleMarkerClick(listing)}
                                >
                                    {activeMarker === index ? (
                                        <InfoWindow onCloseClick={handleOnMouseExit}>
                                            <div>
                                                <img src={listing.imgSrc} alt={listing.title}
                                                     className="listingImage"/>
                                                <div className="listingInfo">
                                                    <h3 className="listingPrice">
                                                        {formatPrice(listing)}
                                                    </h3>
                                                    <p className="listingLocation">{listing.location}</p>
                                                    {/* Additional listing details here */}
                                                </div>
                                            </div>
                                        </InfoWindow>
                                    ) : null}
                                </Marker>
                            ))}
                        </GoogleMap>

                    </div>
                    {/**/}
                    {isLoading ? (
                        <div className="loader">
                            <TailSpin color="#00BFFF" height={80} width={80}/>
                        </div>
                    ) : (<></>)}
                    {/**/}
                    {!isMapVisible ?
                        <main className={`listingsMain ${!isMapVisible ? '' : 'hidden'}`}>
                            {isLoading ? (
                                <div className="loader">
                                    <TailSpin color="#00BFFF" height={80} width={80}/>
                                </div>
                            ) : (

                                <div className="listingsGrid">
                                    {listings.length > 0 ? (
                                        listings.map((listing, index) => (
                                            <a key={listing.id}
                                               onClick={() => handleMarkerClick(listing)}
                                                // href="https://wa.me/14156589193?text=Hello%2C%20I%27m%20interested%20in%20buying%20proprety"
                                                // href={listing.url}
                                               target="_blank" rel="noopener noreferrer"
                                               className="listingCard">
                                                <img src={listing.imgSrc} alt={listing.title} className="listingImage"/>
                                                <div className="listingInfo">
                                                    <h3 className="listingPrice">  {formatPrice(listing)}
                                                    </h3>
                                                    <p className="listingLocation"
                                                       style={{fontSize: 'small'}}>{listing.location}</p>
                                                    <div style={{display: 'flex', gap: '4px', flexWrap: 'wrap'}}>
                                                        <p className="listingPropertyType">{formatPropertyType(listing.raw_data.propertyType)}</p>
                                                        <p className="listingPropertyType">{listing.raw_data.bathrooms} ba </p>
                                                        <p className="listingPropertyType">{listing.raw_data.bedrooms} bds </p>
                                                        <p className="listingPropertyType">{listing.raw_data.livingArea} {listing.raw_data.lotAreaUnit}</p>
                                                        <p className="listingPropertyType" onClick={(e) => {
                                                            e.stopPropagation(); // Prevents the event from propagating to the card's click event
                                                            window.open(listing.url, "_blank");
                                                        }}>{listing.raw_data.daysOnZillow} days
                                                            on
                                                            Zillow</p>
                                                    </div>
                                                    <p className="listingDescription">{listing.description}</p>
                                                    {/* <h2 className="listingTitle">{title}</h2> */}
                                                </div>
                                            </a>
                                        ))
                                    ) : (
                                        <p className="noResults"></p>
                                    )}
                                </div>
                            )}

                        </main>
                        : ''}
                </div>
            </LoadScript>
        );
    }
;

export default ListingsPage;
