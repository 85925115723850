import React, {useState, useEffect} from 'react'; // Добавляем useState
import {useNavigate} from 'react-router-dom';
import {useAuth} from './AuthContext';
import styles from './LoginPage.module.css';
import {jwtDecode} from 'jwt-decode';

const clientId = "507761600002-2ren4lkati40j08rs87csbo6pndiirv5.apps.googleusercontent.com";
const allowedEmails = ["sashakrivolap@gmail.com", "krivolap.dima@gmail.com", "dangost16@gmail.com"];

const LoginPage = () => {
    const navigate = useNavigate();
    const {login} = useAuth();
    const [errorMessage, setErrorMessage] = useState(''); // Новое состояние для сообщения об ошибке

    useEffect(() => {
        window.handleCredentialResponse = function (response) {
            const token =  response.credential;
            const url = `${process.env.REACT_APP_API_URL}/login`;

            const data = {
                token: token,
            };

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(data => {
                    if (response.status === 401) {
                        console.error("Access denied for email");
                        setErrorMessage("Access is denied.\n For access, please contact ");
                    }
                    login(token);
                    navigate('/');
                })
                .catch((error) => {
                    console.error('Error sending login:', error);
                });
        };

        const script = document.createElement('script');
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [navigate, login]);

    return (
        <div className={styles.loginPage}>
            <video autoPlay loop muted className={styles.backgroundVideo}>
                <source src="https://placy.us/assistant/videos/6e4df49fd3dc9376e3dce9dfa75c03ec.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
            <div className={styles.content}>
                <h1>Admin Panel</h1>
                {errorMessage &&
                    <h2>{errorMessage} <a href="https://t.me/SayAppsBot" target="_blank">@SayAppsBot</a></h2>}
                <div id="g_id_onload"
                     data-client_id={clientId}
                     data-callback="handleCredentialResponse">
                </div>
                <div className="g_id_signin" data-type="standard"></div>
            </div>
        </div>
    );
};

export default LoginPage;
