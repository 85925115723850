import React from 'react';
import { Link } from 'react-router-dom';
import Typical from 'react-typical';
import { FaChartBar, FaComments, FaFileAlt, FaBullhorn, FaRocket, FaDatabase } from 'react-icons/fa';
import styles from './HomePage.module.css'; // Switched to CSS module for scoped styles

const HomePage = () => {
  return (
    <div className={styles.homePage}>
      <video autoPlay loop muted className={styles.backgroundVideo}>
        <source src="https://placy.us/assistant/videos/6e4df49fd3dc9376e3dce9dfa75c03ec.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <header className={styles.homeHeader}>
        <h1>Placy</h1>
        <h2>
          <Typical
            loop={Infinity}
            wrapper="d"
            steps={[
              'AI-native', 1000,
              'Real Estate', 1000,
              'Market Place', 1000,
              'Brokerage', 1000,
            ]}
          />
        </h2>
      </header>
      <div className={styles.cardsContainer}>
        <Link to="/analytics" className={`${styles.card} ${styles.analytics}`}><FaChartBar className={styles.icon} />Analytics</Link>
        <Link to="/chat" className={`${styles.card} ${styles.chats}`}><FaComments className={styles.icon} />Chats</Link>
        <Link to="/documents" className={`${styles.card} ${styles.documents}`}><FaFileAlt className={styles.icon} />Documents</Link>
        <Link to="/marketing" className={`${styles.card} ${styles.marketing}`}><FaBullhorn className={styles.icon} />Marketing</Link>
        <Link to="https://placy.us/" className={`${styles.card} ${styles.pro}`}><FaRocket className={styles.icon} />Placy PRO</Link>
        <Link to="/data" className={`${styles.card} ${styles.data}`}><FaDatabase className={styles.icon} />Data</Link>
      </div>
    </div>
  );
}

export default HomePage;
