import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {formatDistanceToNow} from 'date-fns';
import './ChatStyles.css';
import {FaTelegramPlane, FaWhatsapp, FaFacebookF, FaInstagram} from 'react-icons/fa';

const getChannelIcon = (channel) => {
    const iconStyle = {fontSize: '24px'}; // Пример размера иконки, можно добавить другие стили

    switch (channel) {
        case 'telegram':
            return <FaTelegramPlane style={{...iconStyle, color: '#0088cc'}}/>;
        case 'whatsapp':
            return <FaWhatsapp style={{...iconStyle, color: '#25D366'}}/>;
        case 'facebook':
            return <FaFacebookF style={{...iconStyle, color: '#3b5998'}}/>;
        case 'instagram':
            return <FaInstagram style={{...iconStyle, color: '#E1306C'}}/>;
        default:
            return <span style={iconStyle}>🤖</span>; // Default icon or placeholder
    }
};


const ChatList = () => {
    const [chats, setChats] = useState([]);
    const [activeTab, setActiveTab] = useState('ALL'); // 'ALL' или 'LEADS'
    const [totalChats, setTotalChats] = useState(0);
    const [totalLeads, setTotalLeads] = useState(0);
    const [totalAgents, setTotalAgents] = useState(0);

    // Функция для загрузки чатов
    const fetchChats = () => {
        const authToken = localStorage.getItem('token')
        fetch(`${process.env.REACT_APP_API_URL}/chats`, { headers: {'Authorization': authToken}})
            .then(response => response.json())
            .then(data => {
                setChats(data);
                setTotalChats(data.length); // Обновить общее количество чатов
                setTotalLeads(data.filter(chat => chat.isLead).length); // Обновить количество лидов
                setTotalAgents(data.filter(chat => chat.isAgent).length);
            })
            .catch(error => console.error('Error fetching data: ', error));
    };

    useEffect(() => {
        fetchChats(); // Загрузить чаты при монтировании

        const intervalId = setInterval(() => {
            fetchChats(); // Загрузить чаты каждые 5 секунд
        }, 5000);

        return () => clearInterval(intervalId); // Очистить интервал при размонтировании
    }, []);

    // Фильтрация чатов в зависимости от активной вкладки
    const filteredChats = chats.filter(chat => activeTab === 'ALL' || (activeTab === 'LEADS' && chat.isLead) || (activeTab === 'AGENTS' && chat.isAgent));

    return (
        <div className="chatList">
            <div className="tabs stickyTabs">
                <button onClick={() => setActiveTab('ALL')} className={activeTab === 'ALL' ? 'active' : ''}>ALL
                    ({totalChats})
                </button>
                <button onClick={() => setActiveTab('LEADS')} className={activeTab === 'LEADS' ? 'active' : ''}>Leads
                    ({totalLeads}) <span className="">✅</span></button>
                <button onClick={() => setActiveTab('AGENTS')} className={activeTab === 'AGENTS' ? 'active' : ''}>Agents
                    ({totalAgents}) <span className="Icon">👩‍💼</span></button>
            </div>

            <ul>
                {filteredChats.map(chat => {
                    return (
                        <li key={chat.chatId}>
                            <NavLink
                                to={{
                                    pathname: `/chat/${chat.chatId}`,
                                    state: {participant: chat.participant, phoneNumber: chat.phoneNumber}
                                }}
                                className={({isActive}) => isActive ? 'activeChat' : undefined}

                            >
                                <div className="chatPreview">
                                    <span className="chatIcon">{getChannelIcon(chat.channel)}</span>
                                    <span className="chatIcon">
            {chat.isLead ? '✅ ' : chat.isAgent ? '🏠 ' : '👤 '}
          </span>
                                    <div className="chatHeader1">
                                        <div className="chatParticipant">{chat.participant}</div>
                                        <div className="chatLastMessage">{chat.lastMessage}</div>
                                    </div>

                                    <div className="chatMeta">
                                        <span
                                            className="timeAgo">{formatDistanceToNow(new Date(chat.lastMessageTime))} ago</span>
                                        <span
                                            className={`messageCount ${chat.autopilot ? 'messageCountAutopilotOn' : 'messageCountAutopilotOff'}`}>
              {chat.messageCount}
            </span>
                                    </div>
                                </div>
                            </NavLink>
                        </li>
                    );
                })}

            </ul>
        </div>
    );
};

export default ChatList;
