import React, { useState } from 'react';

const DocumentForm = ({ platform, onDocumentGenerated }) => {
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    let documentUrl;
    switch (platform) {
      case 'PandaDoc':
        // Здесь код для генерации документа через PandaDoc
        documentUrl = 'https://www.pandadoc.com';
        break;
      case 'DocuSign':
        // Здесь код для генерации документа через DocuSign
        documentUrl = 'https://www.docusign.com';
        break;
      default:
        console.error('Неизвестная платформа:', platform);
        return;
    }

    // После генерации документа вызываем коллбэк
    onDocumentGenerated(documentUrl);
  };

  return (
    <form className="documentForm" onSubmit={handleSubmit}>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
        required
      />
      <input
        type="text"
        value={fullName}
        onChange={(e) => setFullName(e.target.value)}
        placeholder="Full Name"
        required
      />
      <button type="submit">Generate Document</button>
    </form>
  );
};

export default DocumentForm;
