import React, { useState, useEffect } from 'react';
import './PreferencesPanel.css';

const PreferencesPanel = ({ chatId }) => {
  const [preferences, setPreferences] = useState(null);
  const [conversation, setConversation] = useState(null);
  // Отдельные состояния для текста каждой кнопки
  const [copyPrefButtonText, setCopyPrefButtonText] = useState('Copy');
  const [copyConvButtonText, setCopyConvButtonText] = useState('Copy');
  const [isPanelVisible, setIsPanelVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      console.log("Current chatId:", chatId);
      if (!chatId) return;
  
      try {
        const authToken = localStorage.getItem('token')
        const response = await fetch(`${process.env.REACT_APP_API_URL}/chats/${chatId}`, { headers: {'Authorization': authToken}});
        const data = await response.json();
        setPreferences(data.preferences);
        setConversation(data.conversation);
      } catch (error) {
        console.error('Error fetching chat info:', error);
      }
    };
  
    fetchData();
    const intervalId = setInterval(fetchData, 5000); // Запрашивать данные каждые 5 секунд
  
    return () => clearInterval(intervalId); // Очищаем интервал при размонтировании компонента
  }, [chatId]);

  // Обновленная функция копирования с параметром для установки текста кнопки
  const copyToClipboard = (data, setButtonText) => {
    const jsonStr = JSON.stringify(data, null, 2);
    navigator.clipboard.writeText(jsonStr).then(() => {
      setButtonText('Copied!');
      setTimeout(() => setButtonText('Copy'), 2000);
    }, (err) => {
      console.error('Error copying to clipboard', err);
    });
  };

  const togglePanelVisibility = () => {
    setIsPanelVisible(!isPanelVisible);
  };

  return (
    <div>
      <button onClick={togglePanelVisibility} className={`toggleButton ${isPanelVisible ? 'open' : ''}`}>
        &gt;
      </button>
      {isPanelVisible && (
        <div className="preferencesPanel">
          <h3>Preferences</h3>
          {preferences ? (
            <div className="preferencesContent">
              <pre>{JSON.stringify(preferences, null, 2)}</pre>
              <button onClick={() => copyToClipboard(preferences, setCopyPrefButtonText)} className="copyButton">
                {copyPrefButtonText}
              </button>
            </div>
          ) : (
            <p>Loading preferences...</p>
          )}
          <h3>Conversation</h3>
          {conversation ? (
            <div className="preferencesContent">
              <pre class="conversationContent">{JSON.stringify(conversation, null, 2)}</pre>
              <button onClick={() => copyToClipboard(conversation, setCopyConvButtonText)} className="copyButton">
                {copyConvButtonText}
              </button>
            </div>
          ) : (
            <p>Loading conversation...</p>
          )}
        </div>
      )}
    </div>
  );
};

export default PreferencesPanel;
