// AuthContext.js
import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    // Проверяем localStorage при инициализации
    localStorage.getItem("isAuthenticated") === "true"
  );

  const login = (token) => {
    setIsAuthenticated(true);
    localStorage.setItem("isAuthenticated", "true"); // Сохраняем состояние
    localStorage.setItem("token", token);
  };
  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("isAuthenticated"); // Очищаем состояние
    localStorage.removeItem("token"); // Очищаем состояние
  };

  const getToken = () => {
    return localStorage.getItem("token");
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

