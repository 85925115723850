import React, {useState, useEffect, useRef} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import {FaWhatsapp} from 'react-icons/fa';
import {format} from 'date-fns';
import './ChatStyles.css';
import MessageInputForm from './MessageInputForm';
import PreferencesPanel from './PreferencesPanel';

const ChatMessages = () => {
    const {chatId} = useParams();
    const location = useLocation();

    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [chatInfo, setChatInfo] = useState({participant: 'Unknown', phoneNumber: 'Unknown', channel: 'Unknown'});
    const [isAutopilotEnabled, setIsAutopilotEnabled] = useState(false);
    const [userHasScrolledUp, setUserHasScrolledUp] = useState(false);

    const chatMessagesEndRef = useRef(null); // Step 1: Ref for scrolling
    const chatMessagesListRef = useRef(null); // Ref for the messages container to detect scroll


    const scrollToBottom = () => {
        if (!userHasScrolledUp) {
            chatMessagesEndRef.current?.scrollIntoView({behavior: "smooth"});
        }
    };

    // Detect user scroll
    const handleUserScroll = () => {
        const {scrollTop, clientHeight, scrollHeight} = chatMessagesListRef.current;
        const isAtBottom = scrollHeight - scrollTop === clientHeight;
        setUserHasScrolledUp(!isAtBottom);
    };

    useEffect(() => {
        scrollToBottom();
        // Add scroll event listener to the chatMessagesList container
        const list = chatMessagesListRef.current;
        list.addEventListener('scroll', handleUserScroll);

        return () => list.removeEventListener('scroll', handleUserScroll); // Cleanup
    }, [messages]); // Re-run effect when messages update


    const {participant: participantName, phoneNumber, channel} = chatInfo;

    const fetchMessages = () => {
        const authToken = localStorage.getItem('token')
        fetch(`${process.env.REACT_APP_API_URL}/chats/${chatId}/messages`, { headers: {'Authorization': authToken}})
            .then(response => response.json())
            .then(setMessages)
            .catch(error => console.error('Error fetching messages: ', error));
    };

    const updateAutopilot = (newState) => {
        setIsAutopilotEnabled(newState);
        console.log(`Autopilot was ${newState ? 'enabled' : 'disabled'} from fetched data.`);
    };
    const toggleAutopilot = (pilot) => {
        const newAutopilotState = pilot;
        console.log(`Автопилот ${newAutopilotState ? 'включен' : 'выключен'}.`);
        const url = `${process.env.REACT_APP_API_URL}/switch_autopilot`;
        const data = {
            chatId: chatId,
            pilot: newAutopilotState,
        };
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                setNewMessage('');
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        setIsAutopilotEnabled(newAutopilotState);
    };

    // Function to parse the text and split into regular and bold parts
    const parseMarkdownBold = (text) => {
        // Split text by line breaks first to maintain them
        return text.split('\n').map((paragraph, index) => (
            // For each line, parse bold text
            <React.Fragment key={index}>
                {paragraph.split(/(\*\*[^*]+\*\*)/g).map((part, i) => {
                    if (part.startsWith('**') && part.endsWith('**')) {
                        return <strong key={i}>{part.slice(2, -2)}</strong>;
                    }
                    return part;
                })}
                {index < text.split('\n').length - 1 && <br/>}
            </React.Fragment>
        ));
    };

    const parseTextWithMarkdownAndLinks = (text) => {
        // Regular expression to match bold syntax and URLs
        const regex = /(\*\*[^*]+\*\*)|(\bhttps?:\/\/\S+\b)/g;

        // Splitting the text by line breaks to preserve text structure
        return text.split('\n').map((line, lineIndex, arr) => (
            <React.Fragment key={lineIndex}>
                {/* Splitting each line by the regex to find bold text and URLs */}
                {line.split(regex).map((part, partIndex) => {
                    // If the part is bold text
                    if (part && part.startsWith('**') && part.endsWith('**')) {
                        return <strong key={partIndex}>{part.slice(2, -2)}</strong>;
                    }
                    // If the part is a URL
                    else if (part && /\bhttps?:\/\/\S+\b/.test(part)) {
                        return <a className="wordBreak" href={part} key={partIndex} target="_blank" rel="noopener noreferrer">{part}</a>;
                    }
                    // Returning plain text parts as is
                    return part;
                })}
                {/* Adding line breaks except for the last line */}
                {lineIndex < arr.length - 1 && <br/>}
            </React.Fragment>
        ));
    };


    // Функция для загрузки информации о чате
    const fetchChatInfo = () => {
        if (!location.state) {
            const authToken = localStorage.getItem('token')
            fetch(`${process.env.REACT_APP_API_URL}/chats/${chatId}`, { headers: {'Authorization': authToken}})
                .then(response => response.json())
                .then(data => {
                    console.log(!data.autopilot)
                    updateAutopilot(data.autopilot)
                    setChatInfo({
                        participant: data.participant,
                        phoneNumber: data.phoneNumber,
                        channel: data.channel
                    });
                })
                .catch(error => console.error('Error fetching chat info: ', error));
        } else {
            setChatInfo(location.state);
        }
    };

    useEffect(() => {
        fetchMessages();
        const interval = setInterval(fetchMessages, 5000); // Обновление каждые 5 секунд

        return () => clearInterval(interval); // Очистка интервала
    }, [chatId]);

    useEffect(() => {
        fetchChatInfo();
        const interval = setInterval(fetchChatInfo, 5000); // Обновление каждые 5 секунд

        return () => clearInterval(interval); // Очистка интервала
    }, [chatId, location.state]);

    const handleSendMessage = (message) => {
        const url = `${process.env.REACT_APP_API_URL}/send-message`;
        const data = {
            chatId: chatId,
            message: message,
        };

        console.log('Отправленное сообщение:', message, ' ', url, ' ', JSON.stringify(data));

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                // Обновите состояние сообщений здесь, если необходимо
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };


    return (
        <div className="chatAndPreferencesContainer">
            <div className="chatContainer">
                <div className="chatMessages">
                    <div className="chatHeader">
                        <div>
                            {channel === 'whatsapp' && phoneNumber ? (
                                <a href={`https://wa.me/${phoneNumber.replace(/\D/g, '')}`}
                                   target="_blank" rel="noopener noreferrer"
                                   style={{
                                       textDecoration: 'none',
                                       color: 'white',
                                       display: 'flex',
                                       alignItems: 'center'
                                   }}>
                                    <img src="/WhatsApp_icon.png.webp" alt="WhatsApp"
                                         style={{marginRight: '10px', width: '30px'}}/>
                                    {phoneNumber}
                                </a>
                            ) : channel === 'instagram' ? (
                                <a href={`https://www.instagram.com/${participantName}`}
                                   target="_blank" rel="noopener noreferrer"
                                   style={{
                                       textDecoration: 'none',
                                       color: 'white',
                                       display: 'flex',
                                       alignItems: 'center'
                                   }}>
                                    <img src="/Instagram_icon.png" alt="Instagram"
                                         style={{marginRight: '10px', width: '30px'}}/>
                                    {participantName}
                                </a>
                            ) : channel === 'facebook' ? (
                                <a href={`https://www.facebook.com/${participantName}`}
                                   target="_blank" rel="noopener noreferrer"
                                   style={{
                                       textDecoration: 'none',
                                       color: 'white',
                                       display: 'flex',
                                       alignItems: 'center'
                                   }}>
                                    <img src="/2023_Facebook_icon.svg.webp" alt="Facebook"
                                         style={{marginRight: '10px', width: '30px'}}/>
                                    {participantName}
                                </a>
                            ) : channel === 'telegram' ? (
                                <div style={{display: 'flex', alignItems: 'center', color: 'white'}}>
                                    <img src="/Telegram_logo.svg.webp" alt="Telegram"
                                         style={{marginRight: '10px', width: '30px'}}/>
                                    {participantName}
                                </div>
                            ) : (
                                'Unknown'
                            )}
                        </div>
                        <h2>{participantName || 'Unknown'}</h2>
                        <div className="autopilotSwitch">
                            <text>autopilot</text>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={isAutopilotEnabled}
                                    onChange={() => toggleAutopilot(!isAutopilotEnabled)}
                                />
                                <span className="slider"></span>
                            </label>
                        </div>


                    </div>
                    <div className="chatMessagesList" ref={chatMessagesListRef}>
                        <ul>
                            {messages.map(msg => (
                                <li key={msg.messageId}
                                    className={msg.senderId === 'me' ? "messageFromMe" : "messageFromThem"}>
                                    <div className="messageContent">
                                        <div className="messageText">{parseTextWithMarkdownAndLinks(msg.text)}</div>
                                        <div
                                            className="messageTime">{format(new Date(msg.timeSent), ' HH:mm MMM dd')}</div>
                                    </div>
                                </li>
                            ))}
                            <div ref={chatMessagesEndRef}/>
                            {/* Invisible element for scrolling reference */}
                        </ul>
                    </div>
                    <MessageInputForm onSendMessage={handleSendMessage} chatInfo={chatInfo} />
                </div>
                <PreferencesPanel chatId={chatId}/>
            </div>

        </div>


    );
};

export default ChatMessages;
