import React from 'react';
import { FaInstagram, FaFacebookSquare, FaYoutube, FaGoogle, FaWhatsapp, FaTelegramPlane, FaExternalLinkAlt } from 'react-icons/fa';
import './MarketingPage.css';

// Словарь иконок с инлайновыми стилями для цвета
const channelIcons = {
  Instagram: <FaInstagram style={{ color: '#C13584', fontSize: '1.5rem' }} />,
  FB: <FaFacebookSquare style={{ color: '#4267B2', fontSize: '1.5rem' }} />,
  Youtube: <FaYoutube style={{ color: '#FF0000', fontSize: '1.5rem' }} />,
  Google: <FaGoogle style={{ color: '#4285F4', fontSize: '1.5rem' }} />,
};

const messengerIcons = {
  whatsapp: <FaWhatsapp />,
  telegram: <FaTelegramPlane />,
};



// Mock-данные для маркетинговых кампаний
// Mock-функция для генерации кампаний
const generateMockCampaigns = (count) => {
  const channels = ["Instagram", "FB", "Youtube", "Google", "Organic"];
  const messengers = ["/WhatsApp_icon.png.webp", "/telegram-icon.svg", "/messenger-icon.svg"];

  return Array.from({ length: count }).map((_, index) => ({
    id: index,
    title: `Campaign ${index % 5}`, 
    startDate: new Date(2024, 2, index + 1).toISOString().split('T')[0], // Дата начала компании
    cpa: `${(Math.random() * 5 + 1).toFixed(2)}$`, // CPA
    ctr: `${(Math.random() * 15 + 1).toFixed(2)}`, // CTR
    cpl: `${(Math.random() * 20 + 5).toFixed(2)}$`, // CPL
    spent: `${(Math.random() * 1000 + 500).toFixed(2)}$`, // $ Spent
    leads: Math.floor(Math.random() * 100 + 50), // Number of leads
    channel: channels[Math.floor(Math.random() * channels.length)], // Channel
    targetUrl: "https://wa.me/380958824393", // Target ссылка
    messengerIcon: messengers[Math.floor(Math.random() * messengers.length)], // Icon мессенджера
    creativeImg: `/creative/image${index % 5}.jpg`, // Путь к картинке креатива (замените на ваши пути)
  }));
};

const mockCampaigns = generateMockCampaigns(20);

const MarketingPage = ({ campaigns }) => {
  return (
    <div className="marketingPage">
      <header className="marketingHeader">
        <h1>Active Marketing Campaigns</h1>
        <a> Mocked Data: Organic trafic only is currently testing</a>
      </header>
      <div className="campaignsContainer">
        {mockCampaigns.map(campaign => (
          <div className="campaignCard">
          <img src={campaign.creativeImg} alt="Creative" className="campaignImage" />
          <div className="campaignInfo">
            <h2 className="campaignTitle">{campaign.title}</h2>
            <div className="campaignMetrics">
              <p className="metric">
                <span className="metricTitle">Start Date</span>
                <span className="metricValue">{campaign.startDate}</span>
              </p>
              <p className="metric">
                <span className="metricTitle">CPA</span>
                <span className="metricValue">{campaign.cpa}</span>
              </p>
              <p className="metric">
                <span className="metricTitle">CTR</span>
                <span className="metricValue">{campaign.ctr}%</span>
              </p>
              <p className="metric">
                <span className="metricTitle">CPL</span>
                <span className="metricValue">{campaign.cpl}</span>
              </p>
              <p className="metric">
                <span className="metricTitle">$ Spent</span>
                <span className="metricValue">{campaign.spent}</span>
              </p>
              <p className="metric">
                <span className="metricTitle">Leads</span>
                <span className="metricValue">{campaign.leads}</span>
              </p>
              <p className="metric">
                <span className="metricTitle">Channel</span>
                <span className="metricValue">{channelIcons[campaign.channel]}</span>
              </p>
              <p className="metric">
                <span className="metricTitle">Target:</span>
                <a href={campaign.targetUrl} target="_blank" rel="noopener noreferrer" className="targetLink">
                  {messengerIcons[campaign.messenger]}
                  <FaExternalLinkAlt />
                </a>
              </p>
            </div>
          </div>
        </div>
        
        ))}
      </div>
    </div>
  );
};

export default MarketingPage;
