import React, { useState } from 'react';
import DocumentForm from './DocumentForm'; // Подразумевается, что у вас есть компонент формы
import './DocumentsPage.css';

const DocumentsPage = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState('');

  const handleDocumentGenerated = (url) => {
    window.location.href = url; // Перенаправляем пользователя на URL документа
  };

  const handleDocumentPlatformClick = (platform) => {
    setSelectedPlatform(platform); // Сохраняем выбранную платформу
    setIsFormVisible(true); // Отображаем форму
  };

  return (
    <div className="documentsPage">
      <header className="documentsHeader">
        <h1>Document Platforms</h1>
      </header>
      <div className="documentsContainer">
        <div 
          className={`documentLink ${selectedPlatform === 'PandaDoc' ? 'active' : ''}`} 
          onClick={() => handleDocumentPlatformClick('PandaDoc')}
        >
          <img src="/pandadoc-logo.png" alt="PandaDoc" />
          <span className="documentLinkTitle">PandaDoc</span>
        </div>
        <div 
          className={`documentLink ${selectedPlatform === 'DocuSign' ? 'active' : ''}`} 
          onClick={() => handleDocumentPlatformClick('DocuSign')}
        >
          <img src="/docusign-logo.svg" alt="DocuSign" />
          <span className="documentLinkTitle">DocuSign</span>
        </div>
      </div>
      {isFormVisible && (
        <DocumentForm platform={selectedPlatform} onDocumentGenerated={handleDocumentGenerated} />
      )}
    </div>
  );
};

export default DocumentsPage;
