import React from 'react';
import {NavLink} from 'react-router-dom';
import {
    FiHome,
    FiDatabase,
    FiBarChart2,
    FiCpu,
    FiMessageSquare,
    FiPlayCircle,
    FiHexagon,
    FiUserX, FiZoomIn, FiLink, FiLink2, FiTerminal
} from 'react-icons/fi';
import {FaChartBar, FaComments, FaFileAlt, FaBullhorn, FaRocket, FaDatabase} from 'react-icons/fa';
import './Sidebar.css'; // Assuming you'll write your CSS here

const Sidebar = () => {
    return (
        <div className="sidebar">
            <NavLink to="/"><FiHome/></NavLink>
            <NavLink to="/chat"><FiMessageSquare/></NavLink>
            <NavLink to="/terminal"><FiTerminal/></NavLink>
            <NavLink to="/data"><FiDatabase/></NavLink>
            <NavLink to="/analytics"><FiBarChart2/></NavLink>
            <NavLink to="https://placy.us/"><FiLink2/></NavLink>
        </div>
    );
};

export default Sidebar;
