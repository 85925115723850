import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ChatList from './ChatList';
import ChatMessages from './ChatMessages';
import Sidebar from './Sidebar'; // Import the Sidebar

const ChatsPage = () => {
  return (
    <div className="chatContainer" style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar /> {/* Add the Sidebar here */}
      <ChatList style={{ flex: 1 }} />
      <div style={{ flex: 2 }}>
        <Routes>
          <Route path=":chatId" element={<ChatMessages />} />
        </Routes>
      </div>
    </div>
  );
}

export default ChatsPage;
