import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext'; // Убедись, что импортировал AuthProvider
import PrivateRoute from './PrivateRoute'; // И PrivateRoute тоже
import './App.css';
import './ChatStyles.css';
import LoginPage from './LoginPage';
import HomePage from './HomePage';
import AnalyticsPage from './AnalyticsPage';
import DocumentsPage from './DocumentsPage';
import MarketingPage from './MarketingPage';
import ListingsPage from './ListingsPage';
import ChatsPage from './ChatsPage';
import TerminalPage from './TerminalPage';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<PrivateRoute><HomePage /></PrivateRoute>} />
          <Route path="/analytics" element={<PrivateRoute><AnalyticsPage /></PrivateRoute>} />
          <Route path="/documents" element={<PrivateRoute><DocumentsPage /></PrivateRoute>} />
          <Route path="/marketing" element={<PrivateRoute><MarketingPage /></PrivateRoute>} />
          <Route path="/data" element={<PrivateRoute><ListingsPage /></PrivateRoute>} />
          <Route path="/terminal" element={<PrivateRoute><TerminalPage /></PrivateRoute>} />
          <Route path="/chat/*" element={<PrivateRoute><ChatsPage /></PrivateRoute>} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
